import React from 'react';
import Unity, { UnityContext } from "react-unity-webgl";

import { Person } from "@mui/icons-material";
import { Button, Icon } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography, AppBar, Card, CardActions, CardConent, CardMedia, CssBaseline, Grid, Toolbar, Container } from "@mui/material";
import { PhotoCamera } from '@mui/icons-material';
import ForestIcon from '@mui/icons-material/Forest';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useMoralis } from "react-moralis";
import {
  ConnectButton,
  // Icon,
  TabList,
  Tab,
  // Button,
  Modal,
  useNotification,
  NFTCollection
} from "web3uikit";


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("componentDidCatch",error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}

function BasicButtonGroup() {
  return (
    <ButtonGroup variant="contained" orientation="horizontal" aria-label="outlined primary button group">
      <Button>One</Button>
      <Button>Two</Button>
      <Button>Three</Button>
    </ButtonGroup>
  );
}
 
// function MakeGrid() {
//   return {
    
//   };
// }

const unityContext = new UnityContext({
  loaderUrl: "https://met-hub-webclient-storage-staging145452-staging.s3.amazonaws.com/WebGL.loader.js",
  dataUrl: "https://met-hub-webclient-storage-staging145452-staging.s3.amazonaws.com/WebGL.data.gz",
  frameworkUrl: "https://met-hub-webclient-storage-staging145452-staging.s3.amazonaws.com/WebGL.framework.js.gz",
  codeUrl: "https://met-hub-webclient-storage-staging145452-staging.s3.amazonaws.com/WebGL.wasm.gz",
});

const App = () => {

  const { authenticate, isAuthenticated, isAuthenticating, user, account, logout } = useMoralis();

  const login = async () => {
    if (!isAuthenticated) {

      await authenticate({signingMessage: "Log in using Moralis" })
        .then(function (user) {
          console.log("logged in user:", user);
          console.log(user.get("ethAddress"));
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const logOut = async () => {
    await logout();
    console.log("logged out");
  }

  const { chainId } = useMoralis()
  const userId = user ? user.get("ethAddress") : ""
  
  // const { runContractFunction } = 
  //     useWeb3Contract({
  //     // other options
  //     onSuccess: (tx) => {
  //       console.log("logged in user");
  //     },
  // });
  // if (userId){
  //   if (unityContext && unityContext.send){
  //     unityContext.send("Wallet Sign In Receiver", "TransmitPlayerId", userId);
  //   }
  // }

  return (
    <>
      <CssBaseline/>
      <AppBar position="relative">
        <Toolbar>
          <ForestIcon/>
          <Typography variant="h6">
             Midnight Hub
          </Typography>
          <ConnectButton />
        </Toolbar>
      </AppBar>
      <main>
        <div>
          <Container maxWidth="sm">
            <Typography variant="h2" align="center" color="textPrimary" gutterBottom> Let's Play & Earn</Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Discover great games, play and earn across games
            </Typography>
          </Container>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} 
        >
          <ErrorBoundary>
            <Unity 
              unityContext={unityContext}
              matchWebGLToCanvasSize={true}
              align="center"
              style={{ width: "1280px", height: "480px" }}
            />
          </ErrorBoundary>          
        </div>
        <Typography variant="h3" align="center" color="textPrimary" gutterBottom> My NFT Collection</Typography>
        <div className="collection">
            <NFTCollection
             chain="eth"
             address="0xaba7161a7fb69c88e16ed9f455ce62b791ee4d03"
             limit="20"
             offset="0"
            />
        </div>
 
      </main>
    </>
  );
  // return <div>
  //   <Typography variant="h1">Hello World!</Typography>
  //   <Button 
  //     variant="outlined"
  //     size="large"
  //     startIcon={<Person/>}
  //   >
  //     Material UI
  //   </Button>
  // </div>;
};
 
export default App;