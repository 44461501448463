import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { MoralisProvider } from "react-moralis";

class ErrorBoundary2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("componentDidCatch (2)",error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong (2).</h1>;
    }

    return this.props.children; 
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary2>
      <MoralisProvider serverUrl="https://jbgwupdbjmdf.usemoralis.com:2053/server" appId="5UfInMj1DekgwGlbb7SZarE1IZqAiI9DvJARO41Z">
      <App />
      </MoralisProvider>
    </ErrorBoundary2>
  </React.StrictMode>,
  document.getElementById('root')
);